import { render, staticRenderFns } from "./HeaderSidebar.vue?vue&type=template&id=677c956a&scoped=true"
import script from "./HeaderSidebar.js?vue&type=script&lang=js&external"
export * from "./HeaderSidebar.js?vue&type=script&lang=js&external"
import style0 from "./HeaderSidebar.scss?vue&type=style&index=0&id=677c956a&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "677c956a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HcIcon: require('/drone/src/components/Atoms/Icon/Icon.vue').default,HcText: require('/drone/src/components/Atoms/Text/Text.vue').default,HcLink: require('/drone/src/components/Molecules/Link/Link.vue').default})
